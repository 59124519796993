//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuBox from 'viu/components/viu-box/viu-box.vue';

import './style/slider-componente-style.scss';

export default {
  name: 'SliderComponente',
  components: {
    VueSlider,
    ViuInput,
    ViuLabel,
    ViuBox
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    rate: {
      type: Object,
      default: () => ({})
    },
    allSteps: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Number, Object]
    },
    propSlider: {
      type: [Number, Object]
    },
    propInput: {
      type: [Number, Object]
    },
    propMin: {
      type: [Number, Object]
    },
    propMax: {
      type: [Number, Object]
    },
    labelSlider: {
      type: String,
      default: ''
    },
    postInputSlider: {
      type: String,
      default: ''
    },
    inputLabel: {
      type: String,
      default: ''
    },
    postInputLabel: {
      type: String,
      default: ''
    },
    postInputLabelPrecioFinal: {
      type: String,
      default: ''
    },
    periodo: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    disabledSlider: {
      type: Object,
      default: () => ({})
    },
    typeFee: {
      type: String,
      default: ''
    },
    potenciaActual: {
      type: [Number, String, Object]
    },
    requiredPotencia: {
      type: Boolean,
      default: false
    },
    potenciaPrecio: {
      type: Number
    },
    precioBoe: {
      type: String,
      default: ''
    },
    tarifaAtrActual: {
      type: String,
      default: ''
    },
    tarifaAtrContrato: {
      type: String,
      default: ''
    },
    typeValue: {
      type: String,
      default: ''
    },
    id: {
      type: Number
    },
    idChanged: {
      type: Array
    }
  },
  data: () => ({
    min: 0,
    max: 0,
    slider: null,
    inputModel: null,
    importeFinal: '',
    potencia: '',
    tempPotenciaActual: '',
    potenciaGrouped: false
  }),
  computed: {
    potenciaActualCalc() {
      if (this.checkUndefined(this.tempPotenciaActual) === '') return '-';
      return new Intl.NumberFormat('de-DE').format(Number(this.tempPotenciaActual / 1000));
    }
  },
  watch: {
    slider: {
      inmediate: true,
      handler(val) {
        if (this.slider === '' || this.slider === null || this.slider === undefined) {
          this.slider = JSON.parse(JSON.stringify(this.min));
          return;
        }
        this.inputModel = JSON.parse(JSON.stringify(val));
      }
    },
    inputModel: {
      handler(val) {
        if (val === '' || val === undefined) {
          this.inputModel = null;
        }

        if (this.required && this.inputModel !== '') {
          this.$refs.fee.invalidStored = false;
        }

        if (this.inputModel <= this.min && this.inputModel !== null) {
          this.slider = Number(JSON.parse(JSON.stringify(this.min)));
          this.inputModel = Number(JSON.parse(JSON.stringify(this.min)));
        }

        if (this.inputModel >= this.max && this.inputModel !== null) {
          this.$nextTick(() => {
            this.slider = Number(JSON.parse(JSON.stringify(this.max)));
            this.inputModel = Number(JSON.parse(JSON.stringify(this.max)));
          });
        }

        if (this.inputModel < this.max && this.inputModel !== null && this.inputModel > this.min) {
          this.slider = Number(JSON.parse(JSON.stringify(val)));
          this.inputModel = Number(JSON.parse(JSON.stringify(val)));
        }

        if (this.type === 'individual') this.$emit('input', this.inputModel);

        if (this.type === 'grouped')
          this.$emit('input', {
            p1: this.disabledSlider.totalPeriods.includes('p1') ? this.inputModel : null,
            p2: this.disabledSlider.totalPeriods.includes('p2') ? this.inputModel : null,
            p3: this.disabledSlider.totalPeriods.includes('p3') ? this.inputModel : null,
            p4: this.disabledSlider.totalPeriods.includes('p4') ? this.inputModel : null,
            p5: this.disabledSlider.totalPeriods.includes('p5') ? this.inputModel : null,
            p6: this.disabledSlider.totalPeriods.includes('p6') ? this.inputModel : null
          });

        if (this.type === 'individual' && this.rate.type === 'fijo') {
          this.importeFinal =
              this.rate.values[this.typeValue].price[this.periodo.toLowerCase()] + this.inputModel;
        }

        if (
            this.type === 'individual' &&
            this.rate.type === 'fijo' &&
            this.typeValue === 'feesEnergy'
        ) {
          this.importeFinal =
              (this.rate.values[this.typeValue].price[this.periodo.toLowerCase()] * 1000 +
                  this.inputModel) /
              1000;
        }

        if (this.type === 'grouped' && this.rate.type === 'fijo') {
          let total = 0;
          total += this.disabledSlider.totalPeriods.includes('p1')
              ? this.rate.values[this.typeValue].price.p1
              : 0;

          total += this.disabledSlider.totalPeriods.includes('p2')
              ? this.rate.values[this.typeValue].price.p2
              : 0;

          total += this.disabledSlider.totalPeriods.includes('p3')
              ? this.rate.values[this.typeValue].price.p3
              : 0;

          total += this.disabledSlider.totalPeriods.includes('p4')
              ? this.rate.values[this.typeValue].price.p5
              : 0;

          total += this.disabledSlider.totalPeriods.includes('p5')
              ? this.rate.values[this.typeValue].price.p5
              : 0;

          total += this.disabledSlider.totalPeriods.includes('p6')
              ? this.rate.values[this.typeValue].price.p6
              : 0;

          if (this.typeValue === 'feesEnergy') {
            total += (total * 1000 + this.inputModel) / 1000;
          }

          if (this.typeValue !== 'feesEnergy') {
            total += this.inputModel;
          }

          this.importeFinal = total;
        }
      }
    },
    potencia: {
      handler() {
        this.$emit('changePotencia', this.potencia * 1000);
      }
    }
  },
  created() {
    if (this.allSteps['4'].finished && this.idChanged.includes(this.id)) {
      this.setData();
    }
    if (this.type === 'individual') {
      this.min = JSON.parse(JSON.stringify(this.propMin));
      this.max = JSON.parse(JSON.stringify(this.propMax));
      this.inputModel = JSON.parse(JSON.stringify(this.value));
      this.slider = JSON.parse(JSON.stringify(this.value));
      this.tempPotenciaActual = this.checkUndefined(this.potenciaActual);
      if (this.tarifaAtrActual === this.tarifaAtrContrato) {
        this.potencia = Number(this.tempPotenciaActual / 1000);
      }
    }

    if (this.type === 'grouped') {
      if (
          this.checkUndefined(this.potenciaActual?.p1) ===
          this.checkUndefined(this.potenciaActual?.p2)
      ) {
        this.tempPotenciaActual = this.checkUndefined(this.rate.hiredPower.p1 / 1000);
        this.potenciaGrouped = true;

        if (this.tarifaAtrActual === this.tarifaAtrContrato) {
          this.potencia = Number(this.rate.hiredPower.p1 / 1000);
        }
      }
    }

    if (this.type === 'individual' && this.rate.type === 'fijo') {
      this.importeFinal =
          this.rate.values[this.typeValue].price[this.periodo.toLowerCase()] + this.inputModel;
    }

    if (
        this.type === 'individual' &&
        this.rate.type === 'fijo' &&
        this.typeValue === 'feesEnergy'
    ) {
      this.importeFinal =
          (this.rate.values[this.typeValue].price[this.periodo.toLowerCase()] * 1000 +
              this.inputModel) /
          1000;
    }

    if (this.type === 'grouped' && this.rate.type === 'fijo') {
      let total = 0;
      total += this.disabledSlider.totalPeriods.includes('p1')
          ? this.rate.values[this.typeValue].price.p1
          : 0;

      total += this.disabledSlider.totalPeriods.includes('p2')
          ? this.rate.values[this.typeValue].price.p2
          : 0;

      total += this.disabledSlider.totalPeriods.includes('p3')
          ? this.rate.values[this.typeValue].price.p3
          : 0;

      total += this.disabledSlider.totalPeriods.includes('p4')
          ? this.rate.values[this.typeValue].price.p5
          : 0;

      total += this.disabledSlider.totalPeriods.includes('p5')
          ? this.rate.values[this.typeValue].price.p5
          : 0;

      total += this.disabledSlider.totalPeriods.includes('p6')
          ? this.rate.values[this.typeValue].price.p6
          : 0;

      if (this.typeValue === 'feesEnergy') {
        total += (total * 1000 + this.inputModel) / 1000;
      }

      if (this.typeValue !== 'feesEnergy') {
        total += this.inputModel;
      }

      this.importeFinal = total;
    }

    this.disabledSwitch(this.disabledSlider);
  },
  methods: {
    setData() {
      const per = this.periodo.toLowerCase();
      if (this.typeValue === 'feesEnergy') {
        this.inputModel = this.checkUndefined(this.rate.values[this.typeValue].per);
      }

      if (this.typeValue === 'powerMargin') {
        this.inputModel = this.checkUndefined(this.rate.values[this.typeValue][per]);

        this.potencia = this.checkUndefined(this.rate.hiredPower[per] / 1000);
      }

      if (this.typeValue === 'selfConsumption') {
        this.inputModel = this.checkUndefined(this.rate.values[this.typeValue].per);
      }
    },
    disabledSwitch(value) {
      if (Object.values(value).length === 0) {
        this.$emit('disabled', true);
        return;
      }

      // sacamos el menor de los maximos
      const maximos = Object.values(value.max).splice(0, this.disabledSlider.totalPeriods.length);
      const maximo = Math.min(...maximos);

      // sacamos el mayor de los minimos
      const minimos = Object.values(value.min).splice(0, this.disabledSlider.totalPeriods.length);
      const minimo = Math.max(...minimos);

      if (maximo !== null && minimo !== null && maximo > minimo) {
        if (this.type === 'grouped') {
          this.min = JSON.parse(JSON.stringify(minimo));
          this.max = JSON.parse(JSON.stringify(maximo));
          this.slider = null;
          this.inputModel = null;
          if (value.selected.p1 <= maximo && value.selected.p1 >= minimo) {
            this.inputModel = JSON.parse(JSON.stringify(value.selected.p1));
            this.slider = JSON.parse(JSON.stringify(value.selected.p1));
          }
        }

        this.$emit('disabled', false);

        return;
      }
      this.$emit('disabled', true);
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    moneyFormat(value) {
      if (this.checkUndefined(value) === '') return '-';
      return new Intl.NumberFormat('de-DE').format(Number(value));
    }
  }
};
